import { useEffect, useState } from "react";
import { ToastSeverityMap } from "../../../NewConstants/ToastConstants";

const ToastMessage = ({ message, severity, direction, close = () => {} }) => {
  const [isVisible, setIsVisible] = useState(false); // Start with false for fade-in effect

  useEffect(() => {
    // Trigger fade-in effect
    const fadeInTimer = setTimeout(() => {
      setIsVisible(true);
    }, 10); // Small delay to allow CSS transition to apply

    const fadeOutTimer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => {
        close(false);
      }, 1000); // Wait for fade-out animation duration
    }, 3000);

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
    };
  }, [close]);

  return (
    <div className={`absolute w-full ${direction}-0 flex items-center justify-center pb-6`}>
      <div
        className={`flex items-center justify-between min-w-[50vw] max-w-[90vw] ${
          severity === ToastSeverityMap.error
            ? "bg-[var(--red-1)]"
            : severity === ToastSeverityMap.warning
            ? "bg-[var(--star-yellow-1)]"
            : severity === ToastSeverityMap.success
            ? "bg-[var(--green-1)]"
            : ""
        } text-[var(--neutral-white)] font-bold py-2 pl-4 pr-2 rounded-lg shadow-lg text-lg`}
        style={{
          opacity: isVisible ? 1 : 0,
          transition: "opacity 1s", // Fade-in and fade-out effect
        }}
      >
        <span>{message}</span>
        <img
          className="pl-2"
          src={"./Assets/Images/crossIcon.svg"}
          alt="Close icon"
          onClick={() => close(false)}
        />
      </div>
    </div>
  );
};

export default ToastMessage;
