import axios from "axios";
import {
  chatFeedbackApi,
  fetchDataApi,
  pollyTokenApi,
  starBotAnswerApi,
  submitApi,
  transcribeApi,
  verifyPollyTokenApi,
  generateAudioApi,
  consentApi,
  onboardingApi,
  modelInresponsiveApi,
  middlewareHealthApi
} from "../ENV";
import { fetchApi } from "./FetchAPI";
import { getUserType } from "./Utils";


const project = process.env.REACT_APP_PROJECT;
var errorMessageObj = null
const loadConstants = async () => {
  const module = await import(`../NewConstants/ChatConstants`);
  errorMessageObj = module.errorMessageObj;
};

loadConstants().then(() => {
}).catch(err => {
  console.error('Error loading constants:', err);
});

export async function fetchMessages(access_token, offset, fetchDirection) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };
  const res = await fetchApi(
    fetchDataApi,
    "GET",
    {},
    headerValue,
    offset ? fetchDirection ? { offset: offset, fetchdir: fetchDirection } : {offset: offset} : {}
  );
  return res;
}

export async function submit(access_token, queryObj) {
  var payloadJSON = {...queryObj};
  payloadJSON["user_type"] = getUserType()
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(
    submitApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}


export async function transcribe(access_token, langaugeCode, audioBlob) {
    try{
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: transcribeApi,
        headers: {
          "x-access-token": access_token,
          "x-lang-code": langaugeCode,
          "Content-Type": "audio/mpeg"
        },
        data: audioBlob
      };
      const res = await axios(config);
      return res;
    }
    catch(e) {
      return e;
    }
}

export async function getPollyToken(access_token) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(pollyTokenApi, "GET", {}, headerValue, {}, false);
  return res;
}

export async function verifyPollyToken(access_token, pollyToken) {
  var paramsDic = {
    token: pollyToken,
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(
    verifyPollyTokenApi,
    "GET",
    {},
    headerValue,
    paramsDic
  );
  return res.data;
}

export async function generateSpeech(text, langCode) {
  const voice_id = process.env.REACT_APP_AWS_VOICE_ID;
  var headerValue = {
    "Content-type": "application/json"
  };
  var payloadJSON = {
    text: text,
    voice_id: voice_id,
    langCode: langCode,
  };
  try {
    const res = await fetchApi(generateAudioApi, "POST", JSON.stringify(payloadJSON), headerValue, {}, false);
    if (res?.status === 200) {
      const blob = await res.blob();
      const audioUrl = URL.createObjectURL(blob);
      return {"data": {"url": audioUrl}};
    }
    if (res?.status === 500) {
      return {"data": {"detail": res?.data?.detail || "Unable to generate audio"}};
    }
  } catch(e) {
    return {"data": {"detail": e?.data?.detail || "Unable to generate audio"}};
  }
  
}

// export function generateSpeechText(selectedLanguageCode, audioBlob) {
//     var formData = new FormData();
//     formData.append("file", audioBlob, 'audio-sample.mp3');
//     var config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: speechToTextApi,
//       headers: {
//         "x-lang-code": languageName[selectedLanguageCode],
//         "Content-Type": "audio/mpeg",
//       },
//       data: formData,
//     };

//     return axios(config)
//             .then(res  => {
//               return res.data.text;
//             })
//   }


export async function sendChatFeedback(access_token, chat_block_id, feedback_type, feedback='') {
  var payloadJSON = {
    chat_id: chat_block_id,
    feedback_type: feedback_type,
    feedback: feedback
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(
    chatFeedbackApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function starBotAnswer(access_token, chat_block_id, starFlag) {
  var payloadJSON = {
    chat_id: chat_block_id,
    starred: starFlag
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(
    starBotAnswerApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );

  return res;
}

export async function saveConsent(access_token, consentFlag) {
  var payloadJSON = {
    consent: consentFlag
  };

  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(consentApi, "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {});
  return res;
}

export async function saveOnboarding(access_token, onboardingFlag) {
  var payloadJSON = {
    onboarding: onboardingFlag
  };

  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(onboardingApi, "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {});
  return res;
}

export async function modelInresponsive(access_token, user_type) {
  var payloadJSON = {
    user_type: user_type
  };

  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token
  };

  const res = await fetchApi(modelInresponsiveApi, "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {});
  return res;
}

export async function checkMiddleWareHealth() {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json"
  };

  const res = await fetchApi(middlewareHealthApi, "GET", {}, headerValue, {});
  return res?.error ? false : true;
}