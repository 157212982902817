import React, { useEffect, useRef, useState } from "react";
import "./BottomBar.css";
import ChatInputBar from "../../../Shared/Components/ChatInputBar/ChatInputBar";
import ChatInputButton from "../../../Shared/Components/ChatInputButton/ChatInputButton";
import DefaultChips from "../../../Shared/Components/DefaultChips/DefaultChips";
import { getUserType, saveSelectedDocument } from "../../../Actions/Utils";
import { CTDashaDefaultChipsList, CTDchoDefaultChipsList, NHSRCashaDefaultChipsList, WCDawwDefaultChipsList } from "../../../NewConstants/OptionConstants";

const BottomBar = ({
  project,
  selectedLanguage,
  input,
  setInput = () => {},
  setAudioUrls = () => {},
  audioUrls,
  send = () => {},
  textSize,
  isLoading,
  audioRef,
  selectedDocument,
  stopPlayingAudio = () => {},
  setSpeakerEnabled = () => {},
  setSelectedDocument = () => {},
  setToastErrorMessage = () => {},
  setShowToastMessage = () => {},
  setToastMessageSeverity = () => {}
}) => {
  const [optionSelected, setOptionSelected] = useState(false);
  const [inputText, setInputText] = useState(input);
  const textareaRef = useRef(null);
  const [constants, setConstants] = useState(null);

  const adjustTextareaHeight = (resetFlag) => {
    const textarea = textareaRef.current;
    if (textarea) {
      const lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10
      );
      const maxRows = resetFlag ? 1.8 : 5;
      const newHeight = Math.min(textarea.scrollHeight, maxRows * lineHeight);
      textarea.style.height = `${newHeight}px`;
    }
  };

  const handleChipClick = (chipValue) => {
      if (project === 'GHS') {
        setSelectedDocument(chipValue);
        saveSelectedDocument(chipValue);
      }
      
    setOptionSelected(false);
  };

  useEffect(() => {
    setInput(inputText);
  }, [inputText]);

  const optionClicked = (index, input, image = null) => {
    send(index, input, false, image);
    setOptionSelected(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (optionSelected) {
        setOptionSelected(false);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [optionSelected]);

  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/OptionConstants`);
      setConstants(module);
    }

    loadConstants();
  },[]);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { ASHAdefaultChipsList, CHOdefaultChipsList } = constants;

  return (
    <div className="bottomBarDiv py-4 px-5">
      <div className="flex items-center justify-between gap-3">
        <ChatInputBar
          project={project}
          selectedLanguage={selectedLanguage}
          textareaRef={textareaRef}
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          input={inputText}
          send={send}
          setInput={setInputText}
          adjustTextareaHeight={adjustTextareaHeight}
          textSize={textSize}
          isLoading={isLoading}
          selectedDocument={selectedDocument}
        />
        <ChatInputButton
          selectedLanguageCode={selectedLanguage}
          input={inputText}
          setInput={setInputText}
          setAudioUrls={setAudioUrls}
          audioUrls={audioUrls}
          send={send}
          adjustTextareaHeight={adjustTextareaHeight}
          isLoading={isLoading}
          audioRef={audioRef}
          stopPlayingAudio={stopPlayingAudio}
          setSpeakerEnabled={setSpeakerEnabled}
          setToastErrorMessage={setToastErrorMessage}
          setShowToastMessage={setShowToastMessage}
          setToastMessageSeverity={setToastMessageSeverity}
        />
      </div>
      <div>
        {optionSelected && (
          <div className="mt-3 mb-2 grid gap-3 grid-rows-2 grid-cols-2">
            {project === 'NHSRC' && getUserType() === "ASHA" && NHSRCashaDefaultChipsList.map((option) => {
              return (
                <DefaultChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={optionClicked}
                  textSize={textSize}
                  isLoading={isLoading}
                  onChipClick={handleChipClick}
                />
              );
            })}
            {project === 'WCD' && getUserType() === "AWW" && WCDawwDefaultChipsList.map((option) => {
              return (
                <DefaultChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={optionClicked}
                  textSize={textSize}
                  isLoading={isLoading}
                  onChipClick={handleChipClick}
                />
              );
            })}
            {project === 'CTD' && getUserType() === "ASHA" && CTDashaDefaultChipsList.map((option) => {
              return (
                <DefaultChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={optionClicked}
                  textSize={textSize}
                  isLoading={isLoading}
                  onChipClick={handleChipClick}
                />
              );
            })}
            {getUserType() === "CHO" && CTDchoDefaultChipsList.map((option) => {
              return (
                <DefaultChips
                  key={option.id}
                  option={option}
                  selectedLanguage={selectedLanguage}
                  send={optionClicked}
                  textSize={textSize}
                  isLoading={isLoading}
                  onChipClick={handleChipClick}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomBar;
