import { useRef, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import AudioRecorderDeviceReadiness from "../AudioRecorderDeviceReadiness/AudioRecorderDeviceReadiness";
import RecordedAudioTextDeviceReadiness from "../RecordedAudioTextDeviceReadiness/RecordedAudioTextDeviceReadiness";
import MicropohneOnboarding from "../MicrophoneOnboarding/MicrophoneOnboarding";

const onboarding_delay = process.env.REACT_APP_ONBOARDING_DELAY;

const RecordInputButton = ({
  project,
  selectedLanguageCode,
  largeIcon
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordOnboarding, setIsRecordOnboarding] = useState(false);
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [recordingTime, setRecordingTime] = useState(0);
  const recorderRef = useRef(null);
  const [processAudio, setProcessAudio] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);

  const startRecording = async () => {
    try {
      await Mp3Recorder.start();
      setIsRecording(true);

      recorderRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const startRecordingOnboarding = () => {
    try {
      setIsRecordOnboarding(true);
      setTimeout(() => {
        setIsRecordOnboarding(false);
        startRecording();
      }, 3 * onboarding_delay);
    } catch (error) {
      console.error("Error starting audio recording onboarding:", error);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setAudioBlob(blob); // Store the audio blob in the state
        setProcessAudio(true);
      })
      .catch((e) => console.log(e));
    clearInterval(recorderRef.current);
  };


  return (
    <div>
      <div
        className="flex items-center justify-center px-6 py-5 bg-[var(--border-color)] rounded-xl cursor-pointer hover:bg-[var(--hover-primary-dark)] shadow-md"
        onClick={startRecordingOnboarding}
      >
        <img src={"/Assets/Images/mic.svg"} alt="Mic" className={largeIcon ? 'h-[2rem]': ''} />
      </div>
      {isRecordOnboarding && (
        <MicropohneOnboarding selectedLanguageCode={selectedLanguageCode} onboarding_delay={onboarding_delay} />
      )}
      {isRecording && (
        <AudioRecorderDeviceReadiness project={project} selectedLanguageCode={selectedLanguageCode} recordingTime={recordingTime} onStop={stopRecording} showLoader={processAudio} />
      )}
      {audioBlob && !isRecording && (
        <RecordedAudioTextDeviceReadiness
          project={project}
          selectedLanguageCode={selectedLanguageCode}
          audioBlob={audioBlob}
        />
      )}
    </div>
  );
};

export default RecordInputButton;
