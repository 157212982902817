import React,  { useEffect, useState }  from "react";
import "./AudioRecorder.css";
import { micRecording } from "../../../NewConstants/RecorderConstant";
import LoadingDots from '../LoadingDots/LoadingDots';


const AudioRecorderDeviceReadiness = ({project, selectedLanguageCode, recordingTime, showLoader, onStop = () => {} }) => {
  const [constants, setConstants] = useState(null);
  const animatedWave = () => {
    return (
      <div className="boxContainer">
        <div className="box box1"></div>
        <div className="box box2"></div>
        <div className="box box3"></div>
        <div className="box box4"></div>
        <div className="box box5"></div>
      </div>
    );
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  
  
  useEffect(() => {
    const handleVisibilityChange = () => {
      // stopPlayingAudio(audioRef);
      // setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/IntroductionConstants`);
      
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { devicereadinessAshaRecording, devicereadinessAshaRecordingInstruction } = constants;

  return (
    <>
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="bg-[var(--primary-light)] fixed left-0 bottom-0 w-full rounded-t-3xl h-2/3 flex flex-col p-6 justify-between recorederBox">
        <div className="flex flex-col gap-2">
          <div className="text-s font-normal text-[color:var(--dark-neutral-2)]]">
          {devicereadinessAshaRecordingInstruction[selectedLanguageCode]}  
          </div>

          <div className="text-5xl font-normal pt-5 text-[color:var(--almost-black)] leading-tight">
          {devicereadinessAshaRecording[selectedLanguageCode]}        </div>
        </div>
        <div className="flex gap-2 items-center justify-between">
          <div className="text-xl flex items-center gap-3">
            {formatTime(recordingTime)}
            <div className={`h-2.5 w-2.5 bg-[var(--red-alert)] rounded-full ${!showLoader ? 'animate-ping': ''}`}></div>
          </div>
          {!showLoader && <div>{animatedWave()}</div>}
          {!showLoader ? 
            <div className="bg-[var(--primary-dark)] rounded-lg py-3.5 px-5 text-[color:var(--neutral-white)] flex items-center gap-3" onClick={onStop}>
              <div className="w-3 h-3 bg-[var(--neutral-white)]"></div>
              {micRecording.stop[selectedLanguageCode]}
            </div> :
            <div className="bg-[var(--primary-dark)] rounded-lg py-3.5 px-5 text-[color:var(--neutral-white)] flex items-center gap-3">
              <div className="h-6 flex items-center">
                <LoadingDots />
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default AudioRecorderDeviceReadiness;
