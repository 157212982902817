import React, { useEffect, useRef, useState } from "react";
import "./BottomBarDeviceReadinessRecording.css";
import RecordInputButton from "../../../Shared/Components/RecordInputButton/RecordInputButton";

const BottomBarDeviceReadinessRecording = ({
  project,
  selectedLanguage
}) => {

  const [constants, setConstants] = useState(null);


  useEffect(() => {
    const handleVisibilityChange = () => {
      // stopPlayingAudio(audioRef);
      // setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/DeviceReadinessRecordConstant.js`);

      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { startRecording } = constants;


  return (
    <div className="bottomBarDivRecord py-4 px-5 h-1/4 rounded-tl-3xl rounded-tr-3xl">
      <p className="flex items-center justify-center gap-3 pb-10 text-lg">{startRecording[selectedLanguage]}</p>
      <div className="flex items-center justify-center gap-3">
        
        <RecordInputButton
          largeIcon={true}
          project={project}
          selectedLanguageCode={selectedLanguage}
        />
      </div>
    </div>
  );
};

export default BottomBarDeviceReadinessRecording;
