const StaticHTML = () => {
    return (
      <div
        className="bg-[var(--primary-dark)] flex flex-col bg-blend-color-burn"
        style={{
            backgroundImage: `url("/Assets/Images/wallpaperBg.jpg")`,
            height: "fit-content",
            minHeight: "100vh",
            width: "100vw",
            color: "var(--neutral-white)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "4rem",
            paddingBottom: "4rem",
        }}
      >
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "25px" }}>
            Our Services are daily available from
          </div>
          <div style={{ paddingTop: "1rem", fontSize: "25px" }}>
            <b>8:00 AM</b> to <b>12:00 midnight.</b>
          </div>
          <div style={{ paddingTop: "3rem", fontSize: "15px" }}>
            If you are seeing this page during the working hours, our servers are
            temporarily down for maintenance.
          </div>
          <div style={{ paddingTop: "2rem", fontSize: "15px" }}>
            <b>We'll be back online soon.</b>
          </div>
        </div>
        <div
          style={{
            paddingTop: "3rem",
            paddingBottom: "3rem",
            fontSize: "20px",
          }}
        >
          ---------------------------------
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "25px" }}>हमारी सेवाएँ हर दिन</div>
          <div style={{ paddingTop: "1rem", fontSize: "25px" }}>
            <b>सुबह 8:00 बजे से रात 12:00 बजे</b> तक उपलब्ध हैं।
          </div>
          <div style={{ paddingTop: "2rem", fontSize: "15px" }}>
            यदि आप यह पेज कार्य समय के दौरान देख रहे हैं, तो हमारी सर्वर अस्थायी
            रूप से रखरखाव के लिए बंद है।
          </div>
          <div style={{ paddingTop: "2rem", fontSize: "15px" }}>
            <b>हम जल्दी ही ऑनलाइन होंगे।</b>
          </div>
        </div>
      </div>
    );
  };
  
  export default StaticHTML;
  