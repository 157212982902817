export const loginConstant = {
  mobile: {
    en: "Enter your mobile number",
    hi: "अपना मोबाइल नंबर दर्ज करें",
  },
  pass: {
    en: "Enter the 5 digit OTP",
    hi: "5-डिजिट ओटीपी दर्ज करें",
  },
  support:{
    en:"Call for Support",
    hi:"सहायता के लिए कॉल करें"
  },
  proceedButton:{
    en:"Proceed",
    hi:"आगे बढ़ें"
  },
  otp_title: {
    en: "Enter OTP",
    hi: "ओटीपी लिखें"
  },
  resend_otp: {
    en: "Send OTP Again",
    hi: "फिर से ओटीपी भेजें"
  }
};

export const getOtpSentLabel = (mobileNumber, selectedLanguage) => {
  if (selectedLanguage === "en") {
    return `Check your messages. OTP sent to    +91 ${mobileNumber}`
  }
  else {
    return `अपने SMS चेक करें। ओटीपी     +91 ${mobileNumber} पर भेजा गया।`
  }
}


export const loginErrorMap = {
  username: "User not found.",
  otp: "Incorrect OTP"
}

export const loginErrorLabels = {
  username:{
    en:"Please re-check your mobile number",
    hi:"कृपया अपना मोबाइल नंबर फिर से जांच लें"
    },
  otp: {
    en:"Incorrect OTP",
    hi:"गलत ओटीपी"
  }
}
export const loginAudio = {
  en:"Type the OTP received in your registered mobile number.",
  hi:"अपने रजिस्टर्ड मोबाइल नंबर पर प्राप्त ओटीपी टाइप करें।"
}

export const logoutText = {
  confirmation : {
    en:"Do you want to Logout?",
    hi:"क्या आप लॉगआउट करना चाहते हैं?"
  },
  yes:{
    en:"Yes, Log Out",
    hi:"हाँ, लॉगआउट"
  },
  no:{
    en:"No, Go back",
    hi:"नहीं, चेट पर लौटें"
  }
}