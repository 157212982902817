import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RecordedAudioTextDeviceReadiness = ({ project, selectedLanguageCode, audioBlob }) => {
  const audioRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [constants, setConstants] = useState(null);

  const playAudio = () => {
    if (audioRef.current && audioBlob) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  };

  const handleAudioEnded = () => {
    setShowPopup(true);
  };

  const handleYesClick = () => {
    navigate("/device_readiness/thanku");
  };

  const handleNoClick = () => {
    navigate("/device_readiness/thanku");
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && audioRef.current) {
        audioRef.current.pause();
      }
    };

    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/DeviceReadinessRecordConstant`);
      setConstants(module);
    };

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [project]);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { yes, no, clear, listenVoice } = constants;

  return (
    <div className="bg-[var(--primary-light)] h-2/3 fixed left-0 bottom-0 w-full rounded-t-3xl flex flex-col p-6 justify-between">
      <div className="text-center mb-6 mt-12">
        <h2 className="text-3xl font-normal">{listenVoice[selectedLanguageCode]}</h2>
      </div>
      <div className="flex items-center justify-center mb-6">
        <button
          className="text-white p-3 rounded-full"
          onClick={playAudio}
        >
          {audioBlob && !audioRef.current?.paused ? (
            <img className="w-[6rem]" src={"/Assets/Images/playSpeakerIcon.svg"} alt="Play" />
          ) : (
            <img className="w-[6rem]" src={"/Assets/Images/playIcon.svg"} alt="Stop" />
          )}
        </button>
      </div>
      {audioBlob && (
        <audio ref={audioRef} src={URL.createObjectURL(audioBlob)} onEnded={handleAudioEnded} />
      )}
      {showPopup && (
        <div className="bg-white w-full py-4 px-6 mb-6 rounded-lg">
          <p className="text-lg">{clear[selectedLanguageCode]}</p>
          <div className="flex justify-center gap-4">
            <div className="flex items-center justify-center px-4 py-3 rounded-xl border-1 bg-[var(--green-3)] text-[var(--green-1)] border-[var(--green-2)]" onClick={handleYesClick}>
              <img src='../Assets/Images/likeIcon.svg' alt="like" />
              <span className="text-lg ml-2">{yes[selectedLanguageCode]}</span>
            </div>
            <div className="flex items-center justify-center px-4 py-3 rounded-xl border-1 bg-[var(--red-3)] text-[var(--red-1)] border-[var(--red-2)]" onClick={handleNoClick}>
              <img src='../Assets/Images/dislikeIcon.svg' alt="dislike" />
              <span className="text-lg ml-2">{no[selectedLanguageCode]}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordedAudioTextDeviceReadiness;
