import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { generateSpeech } from "../../../Actions/ChatActions";
import { getUserToken, stopPlayingAudio } from "../../../Actions/Utils";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";
import "./Device_readiness_Form.css";

const FormComponent = ({ project, audioRef , selectedLanguage}) => {
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    mobile: "",
    district: "",
    block: "",
    subcenter: "",
  });
  // const selectedLanguage = "en";
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);

  const [isFormValid, setIsFormValid] = useState(false);
  const [constants, setConstants] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const { name, age, mobile, district, block, subcenter } = formData;
    setIsFormValid(!!(name && age && mobile && district && block && subcenter));
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      navigate("/device_readiness/record");
    }
  };
  useEffect(() => {
    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleAudio = async () => {
      const url = await generateSpeech(formAudio[selectedLanguage], selectedLanguage);
      const newAudio = new Audio(url);
      if (audioRef?.current) {
        audioRef.current.pause();
      }
      if (requestController) {
        requestController.abort();
      }
      audioRef.current = newAudio;
      newAudio.play().catch((err) => {
        console.error("Error playing audio:", err);
      });
    };

    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive]);

  const backArrowClick = () => {
    navigate("/guide");
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../NewConstants/DeviceReadinessFormConstant`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const {formAudio, heading, secondaryHeading, form_headings} = constants;

  return (
    <div className="consent-page h-screen w-screen bg-[var(--light-neutral-2)]">
      <div className="w-full flex items-center justify-between pt-6    text-[color:var(--black)] text-xl font-semibold form-header">
        <div className="loginNextBtn  pl-3 ">
          <NextBtn
            icon={"/Assets/Images/arrowLeftBlack.svg"}
            nextClick={backArrowClick}
          />
        </div>
        {showSpeakerActive ? (
          <div
            className="pr-6  "
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
              stopPlayingAudio(audioRef);
            }}
          >
          <img
                    src={"/Assets/Images/blueSpeaker.svg"}
                    alt="active Speaker"
                  />

          </div>
        ) : (
          <div 
            className=" pr-6"
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
            }}
          >
            <img
              src={"/Assets/Images/crossSpeakerIcon.svg"}
              alt="Inactive Speaker"
            />
          </div>
        )}
      </div>
      <div className="pb-10  bg-[var(--light-neutral-2)] flex items-center justify-center  ">
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-md p-6 rounded-md"
        >
          <h2 className="text-xl font-semibold text-blue-700 mb-2 pb-5">
          {heading[selectedLanguage]}
          </h2>
          <p className="text-gray-600 mb-4 pb-5">{secondaryHeading[selectedLanguage]}</p>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['name'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['age'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['mobile'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <input
              type="tel"
              placeholder="1234567890"
              required
              maxLength={10}
            
              
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              
            />
          </div>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['district'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="district"
              value={formData.district}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white" }}
            >
              <option
                value=""
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Select your District
              </option>
              <option
                value="district1"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                District 1
              </option>
              <option
                value="district2"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                District 2
              </option>
            </select>
          </div>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['block'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="block"
              value={formData.block}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white" }}
            >
              <option
                value=""
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Select your Block
              </option>
              <option
                value="block1"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Block 1
              </option>
              <option
                value="block2"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Block 2
              </option>
            </select>
          </div>

          <div className="mb-4 pb-3">
            <label className="block text-gray-700">
            {form_headings['subcenter'][selectedLanguage]}<span className="text-red-500">*</span>
            </label>
            <select
              name="subcenter"
              value={formData.subcenter}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 bg-white"
              style={{ height: "2.5rem", backgroundColor: "white" }}
            >
              <option
                value=""
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Select your Subcenter
              </option>
              <option
                value="subcenter1"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Subcenter 1
              </option>
              <option
                value="subcenter2"
                style={{ height: "2.5rem", backgroundColor: "white" }}
              >
                Subcenter 2
              </option>
            </select>
          </div>

          <button
            type="submit"
            className={`w-full py-2 px-4 rounded-md text-white ${
              isFormValid ? "bg-[var(--primary-dark)] hover:bg-purple-800" : "bg-[var(--primary-light)]"
            }`}
            disabled={!isFormValid}
          >
            {isFormValid ? form_headings['fill'][selectedLanguage] : form_headings['proceed'][selectedLanguage]}
          </button>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
