export const sideMenuLabels = {
    "ViewTutorial": "view tutorial",
    "HelpAndFeedback": "help and feedback"
}

export const sideMenu = [
    {
        "id": "view tutorial",
        "value": {
            "English": "View Tutorial",
            "हिंदी": "ट्यूटोरियल देखें",
        },
        "icon": "/Assets/Images/youtubeIcon.svg",
    },
    {
        "id": "help and feedback",
        "value": {
            "English": "Help & Feedback",
            "हिंदी": "हेल्प और फीडबैक",
        },
        "icon": "/Assets/Images/supportCircle.svg",
    },
]

export const viewTutorialLinks = {
    "en": "https://www.youtube.com/watch?v=1g78nUdOqVs",
    "hi": "https://youtu.be/i1Gw0rPa2JU"
}