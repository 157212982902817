import { useEffect, useState } from "react";
import { microphone_onboarding } from "../../../NewConstants/MicrophnoeOnboardingConstants";

const ImagePreloader = ({ src }) => {
    useEffect(() => {
        const img = new Image();
        img.src = src;
    }, [src]);

    return null;
};

const MicropohneOnboarding = ({ selectedLanguageCode, onboarding_delay }) => {
    const [screenIndex, setScreenIndex] = useState(microphone_onboarding["seconds"]);
    const imageUrls = [
        `/public/Assets/Images/microphone_onboarding_1_en.png`,
        `/public/Assets/Images/microphone_onboarding_1_hi.png`,
        `/public/Assets/Images/microphone_onboarding_2_en.png`,
        `/public/Assets/Images/microphone_onboarding_2_hi.png`,
        `/public/Assets/Images/microphone_onboarding_3_en.png`,
        `/public/Assets/Images/microphone_onboarding_3_hi.png`,
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setScreenIndex((prevIndex) => prevIndex - 1)
        }, onboarding_delay);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {imageUrls.map((url, index) => (
                <ImagePreloader key={index} src={url} />
            ))}
            <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
            <div className="bg-[var(--neutral-white)] fixed left-0 bottom-0 w-full rounded-t-3xl h-2/3 flex flex-col p-6 justify-between recorederBox leading-loose">
                <img className="h-100" src={`/Assets/Images/microphone_onboarding_${screenIndex}_${selectedLanguageCode}.png`} alt="mic" />
                <div className="flex flex-col items-center my-4 justify-center">
                    <div className="text-xl text-[var(--dark-neutral-2)]">
                        {microphone_onboarding["title"][selectedLanguageCode]}
                    </div>
                    {screenIndex > 0 && <div className="text-[12vh] font-thin">
                        {screenIndex}
                    </div>}
                </div>
            </div>
        </>
    );
}

export default MicropohneOnboarding;
