const NetworkNotification = ({ isOnline, isTryingToConnect, successText, failureText, tryingText, tryAgainText, tryToReconnect = () => { } }) => {
    return (
        <div>
            {!isOnline && <div className="fixed top-0 inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20">
            </div>}
            <div
                style={{
                    position: 'fixed',
                    bottom: '70px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '100%', // Ensure the width matches the BottomBar width
                    maxWidth: '600px', // Match BottomBar's max-width
                    padding: '10px 20px',
                    backgroundColor: isOnline ? 'var(--success-background)' : 'var(--error-background)',
                    color: isOnline ? 'var(--success-text)' : 'var(--error-text)',
                    borderRadius: '5px',
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    gap: '10px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    fontSize: '14px',
                }}

            >
                <span className="flex items-center">
                    {isOnline ? (
                        <img className="pr-2"
                            src={"/Assets/Images/wifi.svg"}
                            alt="wifi"
                        />
                    ) : (
                        <div className="pr-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2945_15408)">
                                    <path d="M0.666992 0.666504L15.3337 15.3332" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.1465 7.37354C11.6926 7.64004 12.2032 7.97373 12.6665 8.36687" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M3.33301 8.36677C4.32106 7.54066 5.51026 6.99091 6.77967 6.77344" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.13965 3.36649C8.56792 3.25141 10.0047 3.42527 11.3643 3.87771C12.7239 4.33014 13.9785 5.05188 15.053 5.99983" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M0.946289 6.00008C1.87107 5.18264 2.93142 4.5329 4.07962 4.08008" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.68652 10.7401C6.36332 10.2593 7.17297 10.001 8.00319 10.001C8.83341 10.001 9.64306 10.2593 10.3199 10.7401" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 13.3335H8.00708" stroke="#695555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2945_15408">
                                        <rect width="16" height="16" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    )}
                    {isOnline ? successText : isTryingToConnect ? tryingText : failureText}
                </span>
                {!isOnline && !isTryingToConnect && (
                    <button onClick={tryToReconnect} className="underline">
                        {tryAgainText}
                    </button>
                )}
            </div>
        </div>
    )
}

export default NetworkNotification;