// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}

.boxContainer {
  display: flex;
  justify-content: space-between;
  height: 36px;
  --boxSize: 6px;
  --gutter: 4px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background: var(--primary-medium-light);
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}


.recorederBox {
  box-shadow: 0px -3px 23.5px 0px var(--shadow-color);
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/AudioRecorderDeviceReadiness/AudioRecorder.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF;AACA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,cAAc;EACd,aAAa;EACb,iDAAiD;AACnD;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,qBAAqB;EACrB,uCAAuC;EACvC,wBAAwB;EACxB,sCAAsC;EACtC,mCAAmC;EACnC,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,qBAAqB;AACvB;;;AAGA;EACE,mDAAmD;AACrD","sourcesContent":["@keyframes quiet {\n  25% {\n    transform: scaleY(0.6);\n  }\n  50% {\n    transform: scaleY(0.4);\n  }\n  75% {\n    transform: scaleY(0.8);\n  }\n}\n\n@keyframes normal {\n  25% {\n    transform: scaleY(1);\n  }\n  50% {\n    transform: scaleY(0.4);\n  }\n  75% {\n    transform: scaleY(0.6);\n  }\n}\n@keyframes loud {\n  25% {\n    transform: scaleY(1);\n  }\n  50% {\n    transform: scaleY(0.4);\n  }\n  75% {\n    transform: scaleY(1.2);\n  }\n}\n\n.boxContainer {\n  display: flex;\n  justify-content: space-between;\n  height: 36px;\n  --boxSize: 6px;\n  --gutter: 4px;\n  width: calc((var(--boxSize) + var(--gutter)) * 5);\n}\n\n.box {\n  transform: scaleY(0.4);\n  height: 100%;\n  width: var(--boxSize);\n  background: var(--primary-medium-light);\n  animation-duration: 1.2s;\n  animation-timing-function: ease-in-out;\n  animation-iteration-count: infinite;\n  border-radius: 8px;\n}\n\n.box1 {\n  animation-name: quiet;\n}\n\n.box2 {\n  animation-name: normal;\n}\n\n.box3 {\n  animation-name: quiet;\n}\n\n.box4 {\n  animation-name: loud;\n}\n\n.box5 {\n  animation-name: quiet;\n}\n\n\n.recorederBox {\n  box-shadow: 0px -3px 23.5px 0px var(--shadow-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
